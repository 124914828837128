





























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Document, Video } from 'common-modules/src/store/interface/Document';

@Component
export default class JwlShowMore extends Vue {
  $refs!: {
    showMoreContainer: HTMLElement;
    showMoreText: HTMLElement;
  }

  @Prop(String)
  jwlText!: string;

  @Prop(Object)
  jwlImage!: Document;

  @Prop(Object)
  jwlVideo!: Video;

  hasMore = true;
  showMore = false;

  @Watch('jwlText')
  checkText (): void {
    this.hasMore = true;
    this.showMore = false;
    this.calculateHeights();
  }

  calculateHeights (): void {
    this.$nextTick(() => {
      const descContainer = this.$refs.showMoreContainer;
      const descText = this.$refs.showMoreText;
      if (descContainer && descText) {
        const outerHeight = descContainer.getBoundingClientRect().height;
        const innerHeight = descText.getBoundingClientRect().height;

        if (outerHeight >= innerHeight) {
          this.hasMore = false;
        }
      }
    });
  }

  toggleShowMore (): void {
    this.showMore = !this.showMore;
  }

  get hasMoreClass (): Record<string, boolean> {
    return {
      'jwl-show-more__text-container--open': this.hasMore && this.showMore,
      'jwl-show-more__text-container--has-more': this.hasMore && !this.showMore,
      'jwl-show-more__text-container--has-not-more': !this.hasMore,
    };
  }

  get videoPosterUrl (): string {
    if (this.jwlVideo && this.jwlVideo.poster) {
      return this.jwlVideo.poster;
    }

    if (this.jwlImage) {
      return this.jwlImage.url;
    }

    return '';
  }

  get cleanedText (): string {
    return this.jwlText.replace('&nbsp;', ' ');
  }

  mounted (): void {
    this.calculateHeights();
  }
}
