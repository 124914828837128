



















































































import CommonError from 'common-modules/src/components/CommonError.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ProgrammeType } from 'common-modules/src/store/interface/Programme';
import JwlToggle from '@/components/jwlToggle.vue';
import jwlShowMore from '@/components/jwlShowMore.vue';
import { Announcement } from '@/store/Announcement';

const JwlButton = () => import('@/components/JwlButton.vue');

@Component({
  components: {
    JwlToggle,
    jwlShowMore,
    CommonError,
    JwlButton,
  },
})
export default class JwlAnnouncementBox extends Vue {
  @Prop(Array)
  courseAnnouncements!: Announcement[] | undefined;

  @Prop(Boolean)
  allowEdit!: boolean;

  activeIndex = 0;
  doShowMore = false;
  textWrapperStyle = '';
  announcements: Announcement[] = [];
  mobileVisibleAnnouncements: number[] = [];
  loaded = false;
  error = null;
  mobileShowMore = false;

  changeAnnouncement (targetIndex: number): void {
    if (this.listAnnouncements.length > targetIndex) {
      this.activeIndex = targetIndex;
    } else {
      this.activeIndex = 0;
    }
    this.doShowMore = false;
    this.textWrapperStyle = '';

    if (this.mobileVisibleAnnouncements.includes(targetIndex)) {
      const mobileIndex = this.mobileVisibleAnnouncements.indexOf(targetIndex);
      this.mobileVisibleAnnouncements.splice(mobileIndex, 1);
    } else {
      this.mobileVisibleAnnouncements.push(targetIndex);
    }
  }

  barActiveClass (index: number): Record<string, boolean> {
    return {
      'jwl-announcement__bar--active': this.activeIndex === index,
    };
  }

  dotActiveClass (index: number, programmeType: ProgrammeType): Record<string, boolean> {
    return {
      'jwl-announcement__dot--bachelor': programmeType === 'bachelor',
      'jwl-announcement__dot--professional': programmeType === 'professional',
      'jwl-announcement__dot--teacher-training': programmeType === 'teacher-training',
      'jwl-announcement__dot--academy': programmeType === 'academy',
      'jwl-announcement__dot--mobile-active': this.mobileVisibleAnnouncements.includes(index),
    };
  }

  showAllAnnouncements (): void {
    this.mobileShowMore = true;
  }

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  get announcement (): Announcement | null {
    return this.listAnnouncements.length > this.activeIndex
      ? this.listAnnouncements[this.activeIndex]
      : null;
  }

  get listAnnouncements (): Announcement[] {
    let { announcements } = this;
    if (this.courseAnnouncements) {
      announcements = this.courseAnnouncements;
    }

    return announcements.slice(0, 50);
  }

  get boxClass (): Record<string, boolean> {
    return {
      'jwl-announcement__box--bachelor': this.announcement?.programmeType === 'bachelor',
      'jwl-announcement__box--professional': this.announcement?.programmeType === 'professional',
      'jwl-announcement__box--teacher-training': this.announcement?.programmeType === 'teacher-training',
      'jwl-announcement__box--academy': this.announcement?.programmeType === 'academy',
    };
  }

  get textWrapperClass (): Record<string, boolean> {
    return {
      'jwl-announcement__text-wrapper--show-overflow': this.doShowMore,
    };
  }

  get dotsClass (): Record<string, boolean> {
    return {
      'jwl-announcement__dots--show-all-announcements': this.mobileShowMore,
    };
  }

  created (): void {
    if (this.courseAnnouncements === undefined) {
      this.$store.dispatch('getData', 'announcement')
        .then((data: Announcement[]) => {
          this.announcements = data;
          this.loaded = true;
        })
        .catch((e) => {
          this.error = e;
        });
    } else {
      this.loaded = true;
    }
  }
}
